.Header {
    font-size: 1.8vw;
    text-transform: uppercase;
    margin: 1vh 0;
}

.SubHeader {
    font-size: 1.4vw;
    margin: 1vh 0;
}

.Prompt {
    font-size: 1.1vw;
    margin: 0;
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
}

.GrayText {
    color: #C4C4C4;
}

.Warning {
    color: #C36261;
}

.NavigationLink{
    text-decoration: none;
    color:black;
    font-size: 1vw;
    margin-top: 2vh;
}

.NavigationLink:hover{
    transition: 0.5s;
    color: #798F96;
}

@media screen and (max-width: 1024px) {
    .Header {
        font-size: 4vw;
    }

    .SubHeader {
        font-size: 3.5vw;
    }

    .Prompt {
        font-size: 3vw;
    }

    .NavigationLink {
        font-size: 4vw;
        margin-top: 1.5vh;
    }
}