.MyPopupBaseContainer,
.MyPopupBaseInner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MyPopupBaseContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    justify-content: center;

    background-color: #00000080;
}

.MyPopupBaseInner {
    background-color: white;
    border-radius: 5px;
    padding-bottom: 3vh;
    opacity: 1;

    max-height: 90vh;

    overflow-y: auto;
}

.MyPopupBaseCloseImg {
    margin: 0.5vw 0.5vw -1.5vw auto;
    width: 2vw;
    height: 2vw;
    cursor: pointer;
    background-color: white;

    transform: rotate(0.125turn);
}

@media screen and (max-width: 1024px) {
    .MyPopupBaseCloseImg {
        margin: 1vw 1vw -4vw auto;
        width: 5vw;
        height: 5vw;
    }
}