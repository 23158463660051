.UsersUIContainer,
.UsersUIHeader {
    display: flex;
}

.UsersUIContainer {
    flex-direction: column;
}

.UsersUIHeader {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.UsersUIHeader > p {
    margin-bottom: 1em;
}

.UsersUITableContainer {
    width: 100%;
}

.UsersUIAdd {
    text-transform: lowercase;
    cursor: pointer;
    color: #c4c4c4;
    margin-top: 1em !important;
    margin-bottom: 2vh !important;
}