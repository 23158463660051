.chartPrompt{
    display: flex;
    font-size: 1vw;
    align-items: center;
}

.chartPrompt > :first-child{
    margin-top: 0.4vh;
    width: 1vw;
    height: 1vw;
    border-radius: 100%;
    margin-right: 0.3vw;
}

.chartPrompt > :last-child {
    margin-right: 1vw;
}

@media screen and (max-width: 1024px) {
    .chartPrompt {
        font-size: 3vw;
    }

    .chartPrompt > :first-child {
        width: 2.5vw;
        height: 2.5vw;
        margin-top: 0;
        margin-right: 0.5vw;
    }
}