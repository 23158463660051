.NoHierarchyContainer,
.NoHierarchyContainerInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.NoHierarchyContainer {
    width: 100vw;
    height: 100vh;
    background-color: #00000080;
}

.NoHierarchyContainerInner {
    background-color: white;
    padding: 5vh 5vw;
    border-radius: 5px;
}