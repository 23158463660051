.OrganizationUIContainer {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.ReportPage {
    padding: 5vh 2vw;
}

.OrganizationUIHeader {
    font-weight: normal;
}

.blockReportPage{
    margin: 1vh 1vw 6vh 1vw;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    overflow: auto;
    box-sizing: border-box;
    padding: 1vh 1.5vw;
}

.headerBlockReportPage {
    max-height: 70vh;
}   

.ReportPromptBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ReportPromptBlock > :first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.coupleReportNameHierarchy{
    display: flex;
    flex-direction: row;
    font-size: 1.2vw;
    align-items: center;
}

.coupleReportNameHierarchy .select select {
    width: fit-content;
}

.coupleReportNameHierarchy :last-child{
    text-transform: none !important;
    width: fit-content;
}

.coupleReportNameHierarchy select {
    color: #5F8BD9;
    border: none;
    font-size: 2vw !important;
}

.coupleChartsInputs{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
}

.coupleChartsInputs > :first-child {
    width: fit-content;
}

.coupleChartsInputs > :last-child{
    margin-left: 2vw;
}

.blockReportPage::-webkit-scrollbar { width: 0.4vw; height: 0;}
.blockReportPage::-webkit-scrollbar-track-piece { background-color: white;}
.blockReportPage::-webkit-scrollbar-thumb { height: 0.1vh; background-color: lightgrey; border-radius: 1vw;}

.reportCoupleButtons{
    display: flex;
    justify-content: end;
    align-items: center;
    position: relative;
}

.reportCoupleButtons > * {
    padding: 1vh 2vw !important;
    width: 20vw;
}

.notificationNewReport{
    background-color: #C36261 !important;
    width: 0.8vw;
    position: absolute;
    z-index: 100000;
    border-radius: 100vw;
    height: 0.8vw;
    right: 15vw;
    top: 2.05vw;
}

.coupleBlockReportPage{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
}

.PeriodPrompt {
    text-align: center;
}

.coupleBlockReportPage :last-child {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end !important;
}

.coupleHeadingButtons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3vh;
}

.blockReportPage h1 {
    font-weight: normal;
}

.ReportInfoDetailedReportHeader {
    text-transform: none !important;
    font-size: 1.5vw !important;
}

.coupleChartsInputsHeader > :first-child {
    width: 16em;
}

@media screen and (max-width: 1024px) {
    .OrganizationUIHeader {
        font-size: 4vw;
    }
    
    .reportCoupleButtons > * {
        width: auto;
    }

    .ReportInfoDetailedReportHeader {
        font-size: 4vw !important;
    }
}