.PrimaryButton,
.SecondaryButton,
.WhiteButton {
    cursor: pointer;
    appearance: none;
    outline: none;
    border: none;
    min-width: 5vw;
    padding: 0.5vw 0.5vw;
    margin: 0.5vh 0.5vw;
    border-radius: 5px;
    font-size: 1.1vw;
}

.PrimaryButton {
    background-color: #5F8BD9;
    color: white;
}

.WideButton {
    padding: 1vw !important;
    width: 100%;
}

.SecondaryButton {
    background-color: transparent;
    color: black;
}

.WhiteButton {
    background-color: white;
    color: #5F8BD9;
    border: 1px solid #5F8BD9;
}

.DisabledButton {
    opacity: 0.5;
}

@media screen and (max-width: 1024px) {
    .PrimaryButton,
    .SecondaryButton,
    .WhiteButton {
        padding: 0.5vh 2vw;
        margin: 1vh 1vw;
        font-size: 3vw;
        border-radius: 20px;
    }

    .WideButton {
        padding: 1vh !important;
    }
}