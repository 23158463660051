.LinkAreaContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.LinkAreaContainer > input[type='checkbox'] {
    transform: scale(1.5,1.5);
}

.linkArea {
    margin-left: 0.5em;
}

.linkArea > * {
    font-size: 0.8vw;
}

.linkArea > a {
    color: #5F8BD9;
    text-decoration: none;
}

@media screen and (max-width: 1024px) {
    .LinkAreaContainer > input[type='checkbox'] {
        transform: scale(3,3) translateX(1vw);
        flex: 0 0 2vw;
        margin-right: 7.5vw;
    }

    .linkArea > * {
        font-size: 3.2vw;
    }
}