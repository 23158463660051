.DefaultBalancesPopupInnerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin: 2vh 3vw;
}

.DefaultBalancesPopupInnerContainer > * {
    display : flex;
    flex-direction: column;
    justify-content: center;
    width: 20%;
}

.DefaultBalancesPopupInnerContainer > * > * {
    text-align: center;
    margin: 1vh 0 !important;
}

.DefaultTariffsPopupSpecial {
    position: relative;
    justify-content: flex-start;
}

.DefaultTariffsPopupSpecial > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}