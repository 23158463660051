.DepartmentInfoContainer {
    display: flex;
    align-items: center;
    width: 100%;
}

.DepartmentInfoContainer {
    flex-direction: column;
}

.DepartmentInfoSuperiorNameContainer > div {
    cursor: pointer;
}

.DepartmentInfoSuperiorName {
    font-size: 1.4vw;
    margin-left: 0.1vw;
    color: black;
}

.DepartmentInfoStructureViewPopup  {
    z-index: 100;
    position: fixed;

    max-width: 50vw;
    max-height: 50vh;
}