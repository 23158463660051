.IconWithMenuContainer {
    position: relative;
}

.IconWithMenuImg {
    width: 1.75vw;
    height: 1.75vw;
}

.IconWithMenuMenu {
    position: absolute;
    top: 0.5vh;
    right: 5.5vw;

    margin: 0.5vw 0;

    background-color: white;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    z-index: 2;
}

.IconWithMenuItemContainer {
    width: 100%;
    margin-bottom: 0.5vh;
}

.IconWithMenuItemContainer:hover {
    background-color: rgba(95, 139, 217, 0.3);
}

.IconWithMenuItem {
    font-size: 1.1vw;
    width: 100%;
    white-space: pre;
    text-align: start;
    cursor: pointer;
    margin-left: 1vw;
    margin-right: 3vw;
}