.chartCouple{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.chartCouple > * {
    width: 45%;
}

.addReportBlock{
    margin-left: 2vw;
    margin-top: 4.2vh;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
}

.futureBlock{
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    padding: 1.5vh 1vw !important;
    width: 20vw;
    margin-left: 2.6vw !important;
}

.OrganizationChartsAddReport {
    color: #C4C4C4;  
    font-size: 1.55vw;
    margin-bottom: 3vh;
}

@media screen and (max-width: 1024px) {
    .chartCouple {
        flex-direction: column;
        justify-content: start;
    }

    .chartCouple > * {
        width: 100%;
    }

    .futureBlock {
        width: 50vw;
    }

    .addReportBlock {
        align-items: center;
    }

    .OrganizationChartsAddReport {
        font-size: 3vw;
    }
}