.EmployeesTableTable {
    width: 100%;
}

.EmployeesTableTable > tr {
    cursor: pointer;
}

.EmployeesTableTable > thead > td {
    background-color: #5F8BD9;
    color: white;
    font-size: 1.1vw;
    font-weight: normal;
    padding: 0.5vh 0.8vw 0.5vh 0.8vw;
    min-width: 5vw;
    text-align: center;
}