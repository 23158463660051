.BaseAdminSettings,
.BaseAdminSettingsLinksContainer {
    display: flex;
    flex-direction: column;

}

.BaseAdminSettingsLinksContainer {
    align-items: center;
    width: 15vw;
}

.BaseAdminSettingsLinksContainer > div {
    cursor: pointer;
    text-align: center;
    margin: 0.25vh auto;
}

.BaseAdminSettingsAvatar {
    width: 12vw;
    height: 12vw;
    border: 1px solid #C4C4C4;
}

.BaseAdminSettingsLinkP {
    margin-left: 1vw;
    margin-bottom: 2vh;

    font-size: 0.8vw;
    color: #5F8BD9;
}

.BaseAdminSettingsLinkP > :first-child {
    margin-right: 0.5vw;
    cursor: pointer;
}

.BaseAdminSettingsLinkP > :last-child {
    font-weight: 700;
    margin-left: 0.5vw;
    cursor: default;
}

.BaseAdminSettingsInner {
    display: flex;
    flex-direction: row;
}

.BaseAdminSettingsInner > :last-child {
    margin-left: 3vw;
    min-width: 60vw;
}

.BaseAdminSettingsInfoContainer {
    display: flex;
    flex-direction: column;
}