.ImagedComponent {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: stretch;
}

.ImagedComponentContainer {
    justify-self: stretch;
    flex-grow: 1;
}

.ImagedComponentContainer > * {
    width: 100%;
}

.ImagedComponentImg {
    width: 1.5vw;
    margin-top: 0.5vh;
}

@media screen and (max-width: 1024px) {
    .ImagedComponentImg {
        margin-top: 0;
        width: 7.5vw;
    }
}