.ErrorComponent {
    margin-left: 0.5vw;
    color: #dc3545;
    font-size: 0.75vw; 
    font-weight: 700;
    margin-bottom: 0.25vh;
}

@media screen and (max-width: 1024px) {
    .ErrorComponent {
        font-size: 3vw;
        margin-bottom: 0;
    }
}