.CRMOrganizationUITemplatesContainer {
    width: 95%;
    margin: auto;
}

/* rows */
.CRMOrganizationUITemplatesContainer > * {
    cursor: pointer;
}

.CRMOrganizationUITemplatesContainer > :first-child {
    cursor: default;
}

/* cells */
.CRMOrganizationUITemplatesContainer > * > * {
    text-align: center;
}