.reportListContainer {
    position: absolute;
    top: 18vh;
    max-height: 40vh;
    max-width: 40vw;
    background-color: white;
    right: 20vw;
    z-index: 10000000000;
}

.ReportsList {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 100%;
    padding-bottom: 1vh;
    border: 0.1vw solid #C4C4C4;
    padding-top: 2vh;
    padding-left: 2vw;
    padding-right:  2vw;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 50vh;
}

.clossContainerReportList {
    align-self: flex-end;
}

.clossContainerReportList img {
    cursor: pointer;
    width: 2.3vw;
    transform: rotate(45deg);
}

.reportListBlocks{
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ReportsListDailyReportButton {
    padding: 3vh 3vw !important;
    margin-bottom: 3vh !important;
}

@media screen and (max-width: 1024px) {
    .reportListContainer {
        max-width: 80vw;
        right: 10vw;
    }

    .reportListBlocks {
        margin-top: -2vw;
    }

    .clossContainerReportList img {
        width: 5vw;
    }
}