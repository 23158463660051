body{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    overflow-x: hidden;
}

#root{
    overflow: hidden;
    min-height: 100vh;
}

input[type='url']:focus {
    border: none;
}

button:focus-visible,
select:focus-visible {
    outline: none;
}