.HierarchyComponent {
    overflow-y:  scroll;
}

.HierarchyComponentInner {
    display: flex;
    flex-direction: column;
    max-height: 60vh;
    margin-left: 2vw;
    margin-right: 2vw;
}

.HierarchyComponentTable{
    margin-top: 2.5vh;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.HierarchyComponentTable > * {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0.5vh 0;
}

.HierarchyComponentTable > * > * {
    width: 15vw;
    text-align: center;
}

.HierarchyComponentTable > * > :last-child {
    width: fit-content;
}

.HierarchyComponent ::-webkit-scrollbar { width: 0.4vw; height: 0;}
.HierarchyComponent ::-webkit-scrollbar-track-piece { background-color: white;}
.HierarchyComponent ::-webkit-scrollbar-thumb { height: 0.1vh; background-color: lightgrey; border-radius: 1vw;}

.NodeHierarchy {
    margin-top:1vh;
    margin-left: 1vw;
}

.NameNodeHierarchy div{
    cursor: pointer;
}

.NameNodeHierarchy > :first-child{
    cursor: pointer;
    width: 1.5vw;
}

.HierarchyComponentTable > * > :first-child,
.HierarchyComponentTable > * > :last-child,
.HierarchyComponentShevron {
    cursor: pointer;
    width: 2vw;
    height: 2vw;
}

.HierarchyComponentColoredBlock {
    padding: 2vh 2vw !important;
    margin: 1vh 1vw 0 0 !important;
    text-align: center;
}

@media screen and (max-width: 1024px) {
    .HierarchyComponentInner {
        width: 90vw;
    }

    .HierarchyComponentTable > * > * {
        width: 20vw;
    }

    .HierarchyComponentTable > * > :first-child,
    .HierarchyComponentTable > * > :last-child,
    .HierarchyComponentShevron {
        width: 5vw;
        height: 5vw;
    }

    .HierarchyComponentPrompt {
        font-size: 2.5vw !important;
    }
}