.PeriodBlock{
    font-weight: bold;
    font-size: 1vw;
    display: flex;
    font-weight: normal;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
}

.PairPeriods,
.PeriodBlockSelect > :last-child {
    border-bottom: 0.1vw solid #C4C4C4;
}

.PairPeriods{
    display: flex;
    align-items: center;
    margin-right: 2vw;
    padding: 0.8vh 1vw;
}

.PeriodBlockSelect{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 2vw;
    padding-top: 1vh;
}

.PeriodBlockSelect > :last-child {
    width: 100%;
}

.PairPeriods img{
    width:1.5vw;
    margin-left: 3vw;
}

.DatePeriod{
    cursor: pointer;
}

.PeriodSelect{
    cursor: pointer;
    border: none;
    background-color: inherit;
    outline: none;
    border : none; 
    color: white;
    font-size: 1vw;
    font-weight: bold;
}

.PeriodSelect option{
    background-color: #798F96;
    color: white;
}

.PeriodPart{
    margin-top: 1.5vh;
    margin-left: 1.9vw;
}

.Calendar {
    z-index: 100000000000;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    flex-direction: column;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.CalendarClose{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer !important;
    z-index: 500000000000000 !important;
    position: absolute;
    right: 15px;
    top: 15px;
}

.CalendarPart{
    display: flex;
    justify-content: center;
    align-items: center;
}

.Calendar img{
    width: 2.5vw;
    min-width: 24px;
    cursor: pointer;
}

.ReportButton, .DisbledReportButton{
    border-radius: 2vw;
    background-color: white;
    color:black;
    cursor: pointer;
    margin-top: 2vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border:0.1vw solid white;
    width: 70%; 
    padding: 0.4vw;
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
}

.DisbledReportButton{
    cursor: default;
    opacity: 0.7;
}

.PeriodBlockButton {
    width: 30%;
}

@media screen and (max-width: 1024px) {
    .PeriodBlock {
        flex-direction: column;
        align-items: center;
    }

    .PeriodBlockSelect > :first-child {
        margin-left: 3vw;
    }

    .PairPeriods img{
        width: 5vw;
        margin-left: 3vw;
    }

    .PeriodBlockButton {
        width: auto;
        margin: auto;
    }
}