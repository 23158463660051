body {
    margin : 0;
}

.AdminHeaderContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    background-color: white;
}

.AdminHeaderContainerInner {
    margin-top: 1vh;

    display: grid;
    grid-template-columns: max-content 1fr max-content;
    align-items: center;
}

.AdminHeaderTitle {
    padding-left: 4vw;
    font-size: 2vw;
}

.AdminHeaderLogoContainer {
    width: 8vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.AdminHeaderLogo {
    width: 2vw;
    height: 2vw;
}

.AdminHeaderHr {
    width: 100%;
    height: 10px;
    border: none;
    margin: 1vh 0 0 0;
    background-color: #142C4B;
}

.AdminFrameInner {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: row;
    align-items: start;
}

.AdminSideMenuContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;

    background-color: white;
}

.AdminFrameBackground {
    position: fixed;
    right: 0;
    bottom: 0;
}

.AdminImgHeader {
    height: 5vh;
}

.AdminHeaderUser {
    align-items: center;
    justify-content: flex-end;
    display: flex;
    margin-right: 3vw;
    word-break: break-all;
    cursor: pointer;
}

.AdminHeaderUser > :last-child {
    margin-left: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AdminHeaderUser > :last-child > img {
    width: 2.6vw;
}