.Chart{
    width: 100%;
    padding: 5vh 2vw 3vh 2vw;
    background-color: white;
    position: relative;
}

.YAxiosContainer{
    position: absolute;
    top: 29.5%;
    height: 39.5vh;
    z-index: 1000;
    left: 6%;
}

.chartForChartJs{
    padding-top: 7vh;
}

.Chart canvas{
    z-index: 1;
}

@media screen and (max-width: 1024px) {
    .Chart {
        padding: 2vh 1vw 0 1vw;
    }
}