.InputV2Base,
.InputV2BaseReadonly {
    outline: none;
    margin-left: 0px;
    padding: 0px;
    font-size: 1.2vw;
    border: none;
}

.InputV2Base:focus-visible,
.InputV2Base:focus,
.InputV2BaseReadonly:focus-visible {
    outline: none;
    border: none;
}

.InputV2Base {
    color: black;
}

.InputV2BaseReadonly {
    color: #C4C4C4;
    background-color: white;
}

@media screen and (max-width: 1024px) {
    .InputV2Base, 
    .InputV2BaseReadonly {
        font-size: 4.8vw;
    }

    .InputV2Base {
        appearance: none;
    }
}