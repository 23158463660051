.CRMOrganizationSettingsUIContentContainer,
.CRMOrganizationSettingsUIRow,
.CRMOrganizationSettingsUIUsagesContainer {
    display: flex;
    align-items: center;

    margin: 2vh auto;
}

.CRMOrganizationSettingsUIContentContainer {
    flex-direction: column;

    width: 60%;
}

.CRMOrganizationSettingsUIContentContainer > * {
    width: 100%;
}

.CRMOrganizationSettingsUIUsagesContainer {
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
}