.SelectV2InputReadonly {
    color: #C4C4C4;
}

.SelectV2Select,
.SelectV2SelectReadonly {
    outline: none;
    margin-left: 0.1vw;
    border: none;
}

.SelectV2Select {
    color: black;
    width: 100%;
}

@media screen and (max-width: 1024px) {
    .SelectV2Select,
    .SelectV2SelectReadonly {
        background-color: transparent;
    }
}