.SimpleHeaderWithLinksContainer,
.SimpleHeaderWithLinksLinksContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.SimpleHeaderWithLinksContainer {
    width: 100%;
    height: 100%;
}

.SimpleHeaderWithLinksHeader {
    margin-top: 1.7vh;
    margin-bottom: 2vh;
    text-align: center;
}

.SimpleHeaderWithLinksHr {
    width: 100%;
    height: 6px;
    margin-block: 0;
    border-width: 0;
    background-color: #142C4B;
}

@media screen and (max-width: 1024px) {
    .SimpleHeaderWithLinksContainer > hr {
        height: 12px;
    }
}