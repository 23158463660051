.InteractiveHintBackground {
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;

    background: #00000080;
    z-index: 500;
}

.InterativeHintContainer {
    position: absolute;

    display: flex;
    flex-direction: column;
    
    z-index: 500;
    min-width: 30vw;
    max-width: 60vw;
    border-radius: 5px;
    background-color: white;

    padding: 0 1vw;
}

.InterativeHintContainer > p > span {
    display: block;
    margin-top: 0.5vh;
}

.InteractiveHintContainerInner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    width: 100%;

    margin-top: 2vh;
    margin-bottom: 1vh;
}