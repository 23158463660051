.SwitcherContainer {
    width: fit-content;

    display: flex;
    flex-direction: row;
    align-items: center;

    margin: 0.5vh 0.5vw;
    padding: 0.5vh 0.5vw;
}

.SwitcherContainer > button {
    margin: 0 !important;
    transition: 0.75s;
}