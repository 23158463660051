.ButtonInteractiveContainer  {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.ButtonInteractiveButton {
    z-index: 2;
}

.ButtonInteractiveImgContainer {
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    opacity: 0;
}

.ButtonInteractiveImgContainer {
    margin: 1px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ButtonInteractiveImg {
    height: 100%;
    max-height: 6vh;
    user-select: none;
}

@media screen and (max-width: 1024px) {
    .ButtonInteractiveAnimatedContainer {
        width: 5vw;
        height: 5vw;
    }
}