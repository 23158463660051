.PopupConfirmHeader {
    font-size: 1.8vw;
}

.PopupConfirmText {
    margin-left: 1vw;
    margin-right: 1vw;
    font-size: 1.1vw;
    word-break: break-all;
}

.PopupConfirmButtonsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.PopupConfirmButtonsContainer > * {
    font-size: 1.1vw;
    padding: 0.5vw 1vw;
    border: 1px solid #5F8BD9;
    border-radius: 5px;
}

.PopupConfirmButtonsContainer > :first-child {
    margin-right: 1vw;
}

.PopupConfirmButtonsContainer > :last-child {
    margin-left: 1vw;
}

.PopupConfirmCloseButton {
    color: #5F8BD9;
    background-color: white;
}

.PopupConfirmConfirmButton {
    color: white;
    background-color: #5F8BD9;
}