.ManagerSettinsUIMainContainer {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.ManagerSettingsUIButtonsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}