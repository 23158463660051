.HeadHierarchy{
    margin-top: 2.5vh;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.HeadHierarchy > * {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0.5vh 0;
}

.HeadHierarchy > * > :first-child {
    width: 15vw;
    word-break: break-all;
}

.HeadHierarchy > * > * {
    width: 20vw;
    text-align: center;
}

.HeadHierarchy > * > :last-child {
    width: 1.5vw;
}

.addDepartmentInHeadHierarchy{
    color: #C4C4C4;
    cursor: pointer;
    text-align: start !important;
    width: 100% !important;
}

.deleteFromFavoritiesImg{
    cursor: pointer;
}

.circleValuesMainDep {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2vh 0;
}

.openFullHierarchy{
    margin-top: 2vh !important;
    margin-left: 10vw !important;
}

@media screen and (max-width: 1024px) {
    .HeadHierarchyPrompt {
        font-size: 2.5vw !important;
    }

    .HeadHierarchy > * > :last-child {
        width: 4vw;
    }
}