.EmployeeInfoTable,
.EmployeeInfoTable > * {
    display: flex;
}

.EmployeeInfoTable {
    width: 90%;

    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.EmployeeInfoTable > * {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;

    width: 100%;
}

.EmployeeInfoTable > div > div {
    width: 45%;
}

.EmployeeInfoDepartmentPanelContainer {
    width: 100%;
    max-width: 60vw;
    overflow-x: auto;
    padding: 0.5vh 0;
    margin: 2vh auto 1vh 0;
}

.EmployeeInfoCheckboxesContainer {
    flex-direction: column !important;
    align-items: flex-start !important;
}

.EmployeeInfoCheckboxesContainer > * {
    margin-bottom: 1vh;
}