.HeaderedComponent {
    margin-bottom: 1vh;
    color: #C4C4C4;
    font-size: 1vw;
    padding-top: 1vh;
}

@media screen and (max-width: 1024px) {
    .HeaderedComponent {
        margin-bottom: 0.5vh;
        font-size: 4vw;
    }
}