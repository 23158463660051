.BalanceUIContainer,
.BalanceUIInformationContainer,
.BalanceUIPaymentInfoPanel {
    display: flex;
    flex-direction: column;
}

.BalanceUITariffItemsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.BalanceUIImportantInfo {
    font-size: 2.8vw;
    font-weight: bold;
    margin: 0;
}

.BalanceUITariffItem {
    font-weight: normal; 
    display: block;
    margin-left: 4vw !important;
}

.BalanceUIExpirationDateContainer {
    margin: 2vh 0;
}

.BalanceUIDateSpan {
    font-weight: bold;
}

.BalanceUIBalanceItemsList {
    list-style-type: disc;
    margin-block: 0;
}

.BalanceUIPaymentContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.BalanceUIPaymentContainer > * {
    width: 45%;
}

.BalanceUIPaymentContainerInner {
    display: flex;
    flex-direction: column;
}

.BalanceUITableContainer {
    width: 100%;
    height: 50vh;
    overflow-y: auto;
}

.BalanceUITable {
    margin-top: 2vh;   
    width: 100%;
}

.BalanceUITable > thead > td {
    background-color: #5F8BD9;
    color: white;
    padding: 0.5vh 1vw;
}

.BalanceUITable > thead > tr,
.BalanceUITable > tr {
    user-select:none;
}

.BalanceUITable > tr {
    cursor: pointer;
}

.BalanceUITable > tr > :first-child {
    position: relative;
}

.BalanceUITable > tr > td {
    font-size: 1.1vw;
}

.BalanceUITable > thead > :last-child,
.BalanceUITable > tr > :last-child {
    text-align: center;
}

.BalanceUITable > tr > :first-child {
    padding-left: 1.5vw;
}

.BalanceUIPaymentInfoPanelContainer {
    position: absolute;
    left: 3vw;
    width: 30vw;
    z-index: 2;
}

.BalanceUIPaymentInfoPanel {
    background-color: white;
}

.BalanceUIPaymentInfoPanel > :first-child {
    text-align: start;
    margin-bottom: 2vh;
    font-weight: bold;
}

.BalanceUIPaymentInfoTable > :last-child > td > * {
    font-weight: bold;
}

.BalanceUIPaymentInfoTable > tr > :first-child {
    width: 50%;
    text-align: left;
}

.BalanceUIPaymentInfoTable > tr > td {
    text-align: right;
}