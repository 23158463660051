.preloader{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.spinerLogo img{
    width: 5vw;
}