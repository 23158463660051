.ManagersTableTable {
    width: 100%;
}

.ManagersTableTable > tr > :last-child {
    cursor: pointer;
}

.ManagersTableTable > thead > th {
    background-color: #5F8BD9;
    color: white;
    font-size: 1.1vw;
    font-weight: normal;
    padding: 0.5vh 0.8vw;
    min-width: 5vw;
}

.ManagersTableTable > tr > td {
    padding-left: 0.8vw;
}