.MonthSelectorContainer {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    width: 100%;
}

.MonthSelectorHeader {
    margin-bottom: 1vh;
}

.MonthSelectorHeader,
.MonthSelectorSelectorReadonly {
    color: #C4C4C4;
}

.MonthSelectorHeader,
.MonthSelectorError {
    font-size: 1.1vw;
}

.MonthSelectorSelector,
.MonthSelectorSelectorReadonly {
    margin-left: 0.1vw;
    font-size: 1.4vw;
    border: none;
}

.MonthSelectorInner {
    margin: 0;
}

.MonthSelectorSelector {
    color: black;
}

.MonthSelectorHr {
    margin-top: 1vh;
    width: 100%;
    border: 0.1vh solid #C4C4C4;
}

.MonthSelectorError {
    margin-left: 0.5vw;
    color: #dc3545;
    font-size: 0.75vw;
    font-weight: 700;
    margin-bottom: 0.5vh;
}

.MonthSelectorSpan {
    margin-left: 0.25vw;
    margin-right: 1vw;
    font-size: 1.1vw;
    color: #808080
}