.ColorBlock{
    padding: 2vh 1vw !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

@media screen and (max-width: 1024px) {
    .ColorBlock {
        padding: 1vh 1vw !important;
    }
}