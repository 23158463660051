.EmployeeInfoDepartmentsPanelContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.EmployeeInfoDepartmentsPanelSelect {
    border: none;
    font-size: 1.4vw;
    text-align: center;
    overflow-wrap: break-word;
}

.EmployeeInfoDepartmentsPanelTable {
    border-spacing: 1vw 2.5vh;
}

.EmployeeInfoDepartmentsPanelTable > tbody > tr > td {
    min-width: 10vw;
    max-width: 20vw;
}

.EmployeeInfoDepartmentsPanelTable > tbody > :first-child > td {
    text-align: center;
}

.EmployeeInfoDepartmentsPanelRemoveButton,
.EmployeeInfoDepartmentsPanelAddButton {
    cursor: pointer;
    border: none;
    width: fit-content;
}

.EmployeeInfoDepartmentsPanelAddButton {
    font-size: 1vw;
    margin-left: 0.5vw;
    padding: 0 1vw;
    background-color: transparent;
    color: #C4C4C4
}

.EmployeeInfoDepartmentsPanelRemoveButton {
    padding: 0 0 0.2vw 1vw;
    font-size: 0.8vw;
    color: #C4C4C4;
    background-color: white;
}