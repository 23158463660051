.SimpleHeaderContainer {
    width: 100%;
    height: 100%;
}

.SimpleHeaderHeader {
    margin-top: 1.7vh;
    margin-bottom: 2vh;
    text-align: center;
}

.SimpleHeaderContainer > hr {
    width: 100%;
    height: 6px;
    margin-block: 0;
    border-width: 0;
    background-color: #92E3DA;
}

@media screen and (max-width: 1024px) {
    .SimpleHeaderContainer > hr {
        height: 12px;
    }
}