.InformationComponentContainer{
    margin-top: 9vh;
    margin-bottom: 5vh;

    display: flex;
    flex-direction: column;
}

.InformationComponentLink{
    color: #2F80ED;
    text-decoration: none;
}

.InformationComponentEmailLink {
    text-align: center;
    text-decoration: none;
    margin: 1vh auto auto 0 !important;
}