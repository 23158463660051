.EmployeeAddPopupButtonsBlock {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
}

.EmployeeAddPopupButtonsBlock > * {
    cursor: pointer;
}

.EmployeeAddPopupButtonAdd {
    margin-left: 1vw;
    padding: 1vh 1vw;
    color: white;
    background-color: #5F8BD9;
    border-radius: 5px;
}

@media screen and (max-width: 1024px) {
    
    @supports (-webkit-appearance: none) {
        .EmployeeAddPopupButtonsBlock {
            width: auto;
            margin-left: auto;
            margin-right: 5%;
            justify-content: end;
            margin-bottom: 10vh;
        }
    }
}