.CommonPage{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-height: 90vh;
}

.CommonPageInner{
    width: 25vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.CommonPageHeader {
    margin-top: 1.7vh;
    margin-bottom: 2vh;
    font-size: 2vw;
    text-align: center;
}

.MainContainer {
    margin-left: 1.5vw;
    margin-right: 3vw;
}

.Shadow {
    box-shadow: 0px 2px 10px rgba(0,0,0,0.08);
    border-radius: 5px;
    padding: 2vh 1vw;
    margin: 1vh 0;
}

.ButtonsContainer {
    width: fit-content;
    margin-left: auto;
    margin-right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.SettingsTable,
.SettingsVertical {
    width: 100%;
}

.SettingsTable,
.SettingsTable > *,
.SettingsVertical,
.SettingsVertical > * {
    display: flex;
}

.SettingsTable {
    flex-direction: column;
    align-items: stretch;
}

.SettingsTable > * {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
}

.SettingsTable > * > * {
    width: 45%;
}

.SettingsVertical,
.SettingsVertical > * {
    flex-direction: column;
    align-items: stretch;
}

.SettingsVertical > * > * {
    width: 100%;
}

@media screen and (max-width: 1280px) {
    .SignUpForm {
        width: 35vw;
    }
}

@media screen and (max-width: 1024px) {
    .CommonPageInner {
        width: 75vw;
    }

    .CommonPageHeader {
        font-size: 8vw;
    }
}