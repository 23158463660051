.circleValue,
.circleValue > * {
    border-radius: 100%;
}

.circleValue{
    padding: 0.6vw;
    transform: rotate(0.25turn);
    box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
}

.circleValue > *{
    width: 2.5vw;
    height: 2.5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    transform: rotate(-0.25turn);
    padding: 0.7vw !important;
    margin: 0 !important;
}

@media screen and (max-width: 1024px) {
    .circleValue > * {
        width: 8vw;
        height: 8vw;
        padding: 2vw !important;
    }

    .circleValue {
        padding: 2vw;
    }
}