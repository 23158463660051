.ProfileUIContainer,
.ProfileUIContainer > * {
    display: flex;
    flex-direction: column;
}

.ProfileUIContainer {
    justify-content: space-between;

    width: 50%;
    height: 100%;
}

.ProfileUILangSelectContainer > * {
    width: 50%;
}

.ProfileUIInteractiveHintsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}

.ProfileUIInteractiveHintsContainer > * {
    margin: 1vh 0 !important;
}

.ProfileUIInteractiveHintsContainer > div > input {
    transform: scale(1.25);
    margin-right: 0.5vw;
}