.ReportImageBlock{
    font-size: 1vw;
    padding: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.ReportHintImageBlock {
    margin-right: 0.75vw;
    margin-left: auto;
}

.ReportHintImageBlock img {
    width: 32px;
    height: 32px;
}

#reportHint {
    border-radius: 5px;
    padding: 1vw 0 1vw 1vw;
    background-color: white;
    box-shadow: 0px 2px 10px #E4E4E4;
    position: absolute;
    width: 50vw;
    top: 4vh;
    right: 3vw;
    z-index: 100;
}

#reportHint > p {
    margin: 0.2em;
}

.ReportHintTitle {
    margin-bottom: 1em !important;
}

.ReportHintSubTitle {
    margin-top: 1em !important;
}

@media (max-width: 1024px){
    .ReportHintImageBlock {
        margin-right: 2vw;
    }

    .ReportImage {
        width: 50vw;
    }
    
    #reportHint {
        width: 80vw;
        top: -3vh;
        right: 8vw;
    }

    #reportHint > p[class='SubHeader'] {
        font-size: 3vw;
    }

    #reportHint > p[class='Prompt'] {
        font-size: 2.5vw;
    }

    .ReportHintImageBlock img {
        width: 64px;
        height: 64px;
    }
}