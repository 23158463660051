.periodBlockInContainer{
    position: absolute;
    right: 2vw;
    top: 20vh;

    max-width: 60%;
    padding: 2vh 2vw;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    z-index: 100000000000000;
    background-color: white;
}

.periodBlockInContainerHeader{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.periodBlockInContainerHeader :last-child {
    width: 2vw;
    transform: rotate(45deg);
    cursor: pointer;
    opacity: 0.5;
}

@media screen and (max-width: 1024px) {
    .periodBlockInContainer {
        padding-top: 1vh;
        top: 17vh;
        right: 10vw;
    }

    .periodBlockInContainerHeader :last-child {
        width: 5vw;
    }
}