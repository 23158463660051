.DailyReportUI {
    min-height: 100vh;
}

.DailyReportUI,
.DailyReportUIContentContainer {
    display: flex;
    flex-direction: column;
}

.DailyReportUIContentContainer {
    margin: 1vh 2vw 5vh 2vw;
}

.DailyReportUIHeadRow,
.DailyReportUIHeadSelectors {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.DailyReportUIHeadRow {
    width: 100%;
}

.DailyReportUIHeadSelectors {
    justify-content: space-between;
}

.DailyReportUIHeadSelectors > * > p {
    font-weight: bold;
}

.DailyReportUIBack {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.DailyReportUIHeadRow > * > * {
    margin: 1vh 3vw;
    white-space: nowrap;
}

.DailyReportUIBackImg {
    width: 1.25vw; 
    height: 1vw;
    margin-left: 1vw;
    margin-right: 1vw;
}

@media screen and (max-width: 1024px) {
    .DailyReportUIContentContainer {
        margin-top: 3vh;
    }

    .DailyReportUIHeadRow {
        flex-direction: column;
        align-items: flex-start;
    }

    .DailyReportUIHeadSelectors {
        width: 100%;
    }

    .DailyReportUIHeadSelectors > * {
        min-width: 30%;
    }
}