.EmployeeSettingsUIContainer {
    margin-bottom: 5vh;
}

.EmployeeSettingsUIContainer,
.EmployeeSettingsUIContainer > * {
    width: 100%;
}

.EmployeeSettingsUIInvitateContainer {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
}

.EmployeeSettingsUIInvitationContainer {
    display: flex;
    flex-direction: column;
}

.EmployeeSettingsUIInvitationContainer > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.EmployeeSettingsUIInvitationContainer > div > * {
    width: 45%;
}

.EmployeeSettingsUIActionButtonsContainer {
    display: flex;
    flex-direction: row;
}