.ReportBlock, .ReportBlockNotLoad{
    width: 100%;
    padding: 2vh 1vw;
    border-radius: 5px;
    border: 0.1vw solid #C4C4C4;
    color : black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2vh;
    cursor: pointer;
}

.ReportBlock div,
.ReportBlockNotLoad div {
    margin-top: 0.75vh;
    margin-bottom: 0.75vh;
}

.ReportBlock p,
.ReportBlockNotLoad p {
    margin-top: 0.75vh !important;
    margin-bottom: 0.75vh !important;
}

.PeriodBlockDelete{
    margin-left: auto;
    cursor: pointer;
    margin-top: -1.5vh !important;
    margin-bottom: -1.5vh !important;
}

.ReportBlockMobileFont {
    margin: 0.25vh 0.25vw !important;
}

.ReportBlockNotLoad{
    opacity: 0.6;
    cursor: default;
}

.PeriodBlockDelete img{
    width: 1.5vw;
    transform: rotate(45deg)
}

@media screen and (max-width: 1024px) {
    .PeriodBlockDelete img {
        width: 5vw;
    }

    .ReportBlockMobileFont {
        font-size: 3vw;
    }
}