.ManagerInfoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ManagerInfoContainer > * {
    width: 100%;
}

.ManagerInfoRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ManagerInfoRow > * {
    width: 45%;
}