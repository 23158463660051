.RechargeBalancePopupContentContainer {
    width: 60%;
}

.RechargeBalancePopupContentContainer > * > input {
    font-size: 1.1vw;
}

.RechargeBalancePopupContentContainer > p {
    text-align: center;
}

.RechargeBalancePopupRow {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.RechargeBalancePopupRow > select {
    width: fit-content;
}

.RechargeBalancePopupBottomContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.RechargeBalancePopupLink {
    color: #2F80ED;
    text-decoration: none;
    font-size: 0.9vw !important;
}