.AdminSideMenuItemContainer {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    margin: 0.5vh 0;
    cursor: pointer
}

.AdminSideMenuItemContainer > img {
    width: 1.8vw;
    height: 1.8vw;
    margin: 2vh 2vw;
}

.AdminSideMenuItemContainer > p {
    font-size: 1.1vw;
}