.StructureComponentContainer {
    display: flex;
    flex-direction: column;
    user-select: none;

    padding: 2vh 2vw;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    overflow: auto;
}

.StructureComponentHeader {
    font-size: 1.4vw;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.StructureComponentHeader > :last-child {
    margin-left: 0.5vw;
}

.StructureComponentItem {
    margin-left: 1vw;

    display: flex;
    flex-direction: column;
}

.StructureComponentNameBox {
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 1.4vw;
    margin-bottom: 0.2vh;
}

.StructureComponentNameBox > * {
    margin-right: 0.5vw;
    max-width: 50vw;
    overflow-wrap: break-word;
    cursor: pointer;
}

.StructureComponentExtend {
    width: 1vw;
    height: 1vw;
    text-align: center;
}

.StructureComponentDividerSpan {
    cursor: default;
}

.StructureComponentEditSpan,
.StructureComponentDividerSpan,
.StructureComponentDeleteSpan {
    font-size: 0.7vw;
    color: #C4C4C4;
}

.StructureComponentButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
}

.StructureComponentEditSpan {
    cursor: pointer;
}

.StructureComponentButtonsContainer > * {
    margin: auto 0.5vw;
    font-size: 1.1vw;
    border: none;
    cursor: pointer;
}

.StructureComponentDeleteButton {
    background-color: white;
}

.StructureComponentAddButton {
    padding: 0.5vw 1vw;
    background-color: #5F8BD9;
    color: white;
    border-radius: 5px;
}