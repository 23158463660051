#payment-form {
    margin: 2.25vh 2.25vw;
}

#payment-form,
.StripePaymentUIPopupContainer > * > *{
    min-width: 400px;
}

#StripepaymentUIEmail {
    border-radius: 6px;
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    font-size: 16px;
    width: 100%;
    background: white;
    box-sizing: border-box;
}

.StripePaymentUIButtonBlock {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1vh;
    margin-bottom: -1vh;
}

.StripePaymentUIButtonBlock > button {
    padding: 1.1vh 5vw;
    font-size: 1.4vw;

}